import IMask from 'imask';

export class InputMask {
    static Init(element: any, mask: string | RegExp, isRegEx: any, returnRawValue: boolean, dotNetObject: any): void {
        var pattern: RegExp;
        if (isRegEx){
            pattern = new RegExp(mask);
        }
        else{
            pattern = mask as RegExp;
        }

        var opts = {
            mask: pattern,
            commit: function (value: any, masked: any) {
                if (returnRawValue === true)
                    dotNetObject.invokeMethodAsync('returnCurrentValue', this.unmaskedValue);
                else
                    dotNetObject.invokeMethodAsync('returnCurrentValue', value);
            }
        };
        var customMask = IMask(element, opts);
        element.InputMask = customMask;
    }
    static Remove(element: any): void {
        if (element && element.customMask){
            element.customMask.destroy();
            delete element.customMask;
        }
    }
}
