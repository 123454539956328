export class ClickIn {
    static Init(element: any, dotnetHelper: any): void {
        var f = (event: MouseEvent) => {
            if (event.target == element){
                if (dotnetHelper){
                    dotnetHelper.invokeMethodAsync("InvokeClickIn");
                }
            } 
        };
        setTimeout(()=>{
            element.ClickIn = f;
            element.addEventListener("click", f);
        }, 500);
    }
    
    static Remove(element: any): void {
        if (element && element.ClickIn){
            element.removeEventListener("click", element.ClickIn);
            delete element.ClickIn;
        }
    }
}
