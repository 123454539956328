import { UserManager } from "./UserManager";
import { OidcAuthorizeService } from "./OidcAuthorizeService";

exports.AuthenticationService = exports.AuthenticationResultStatus = exports.AccessTokenResultStatus = void 0;
function isApiAuthorizationSettings(settings: any) {
    return settings.hasOwnProperty('configurationEndpoint');
}

export class AuthenticationService {
    static _infrastructureKey: string;
    static _pendingOperations: any;
    static _initialized: any;
    static instance: OidcAuthorizeService;

    static init(settings: any) {
        // Multiple initializations can start concurrently and we want to avoid that.
        // In order to do so, we create an initialization promise and the first call to init
        // tries to initialize the app and sets up a promise other calls can await on.
        if (!AuthenticationService._initialized) {
            AuthenticationService._initialized = AuthenticationService.initializeCore(settings);
        }
        return AuthenticationService._initialized;
    }
    static handleCallback() {
        return AuthenticationService.initializeCore(null);
    }
    static async initializeCore(settings: any) {
        const finalSettings = settings || AuthenticationService.resolveCachedSettings();
        if (!settings && finalSettings) {
            const userManager = AuthenticationService.createUserManagerCore(finalSettings);
            if (window.parent !== window && !window.opener && (window.frameElement && userManager.settings.redirect_uri &&
                location.href.startsWith(userManager.settings.redirect_uri))) {
                // If we are inside a hidden iframe, try completing the sign in early.
                // This prevents loading the blazor app inside a hidden iframe, which speeds up the authentication operations
                // and avoids wasting resources (CPU and memory from bootstrapping the Blazor app)
                AuthenticationService.instance = new OidcAuthorizeService(userManager);
                // This makes sure that if the blazor app has time to load inside the hidden iframe,
                // it is not able to perform another auth operation until this operation has completed.
                AuthenticationService._initialized = (async () => {
                    await AuthenticationService.instance.completeSignIn(location.href);
                    return;
                })();
            }
        }
        else if (settings) {
            const userManager = await AuthenticationService.createUserManager(settings);
            AuthenticationService.instance = new OidcAuthorizeService(userManager);
        }
        else {
            // HandleCallback gets called unconditionally, so we do nothing for normal paths.
            // Cached settings are only used on handling the redirect_uri path and if the settings are not there
            // the app will fallback to the default logic for handling the redirect.
        }
    }
    static resolveCachedSettings() {
        const cachedSettings = window.sessionStorage.getItem(`${AuthenticationService._infrastructureKey}.CachedAuthSettings`);
        return cachedSettings ? JSON.parse(cachedSettings) : undefined;
    }
    static getUser() {
        return AuthenticationService.instance.getUser();
    }
    static getAccessToken(options: any) {
        return AuthenticationService.instance.getAccessToken(options);
    }
    static getIdToken(options: any) {
        return AuthenticationService.instance.getIdToken(options);
    }
    static signIn(state: any) {
        return AuthenticationService.instance.signIn(state);
    }
    static async completeSignIn(url: any) {
        let operation = this._pendingOperations[url];
        if (!operation) {
            operation = AuthenticationService.instance.completeSignIn(url);
            await operation;
            delete this._pendingOperations[url];
        }
        return operation;
    }
    static signOut(state: any) {
        return AuthenticationService.instance.signOut(state);
    }
    static async completeSignOut(url: any) {
        let operation = this._pendingOperations[url];
        if (!operation) {
            operation = AuthenticationService.instance.completeSignOut(url);
            await operation;
            delete this._pendingOperations[url];
        }
        return operation;
    }
    static async createUserManager(settings: any) {
        let finalSettings;
        if (isApiAuthorizationSettings(settings)) {
            const response = await fetch(settings.configurationEndpoint);
            if (!response.ok) {
                throw new Error(`Could not load settings from '${settings.configurationEndpoint}'`);
            }
            const downloadedSettings = await response.json();
            finalSettings = downloadedSettings;
        }
        else {
            if (!settings.scope) {
                settings.scope = settings.defaultScopes.join(' ');
            }
            if (settings.response_type === null) {
                // If the response type is not set, it gets serialized as null. OIDC-client behaves differently than when the value is undefined, so we explicitly check for a null value and remove the property instead.
                delete settings.response_type;
            }
            finalSettings = settings;
        }
        window.sessionStorage.setItem(`${AuthenticationService._infrastructureKey}.CachedAuthSettings`, JSON.stringify(finalSettings));
        return AuthenticationService.createUserManagerCore(finalSettings);
    }
    static createUserManagerCore(finalSettings: any) {
        const userManager = new UserManager(finalSettings);
        
        userManager.events.addUserSignedOut(async () => {
            userManager.removeUser();
        });
        return userManager;
    }
}
