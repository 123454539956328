export class Downloader {
    static async DownloadFileFromStream(fileName: any, contentStreamReference: { arrayBuffer: () => any; }) {
        const arrayBuffer = await contentStreamReference.arrayBuffer();
        const blob = new Blob([arrayBuffer]);
        const url = URL.createObjectURL(blob);
    
        triggerFileDownload(fileName, url);
    
        URL.revokeObjectURL(url);
    }
}

function triggerFileDownload(fileName: any, url: string) {
    const anchorElement = document.createElement('a');
    anchorElement.href = url;
    anchorElement.download = fileName ?? '';
    anchorElement.click();
    anchorElement.remove();
}

