import tinymce, { Editor } from 'tinymce';

export class TinyMCEWrapper {
  static insertContent(id: string | number, content: any, args: any) {
    insertContent(id, content, args);
  }
  static updateMode(id: string | number, disable: any) {
    updateMode(id, disable);
  }
  static updateValue(id: string | number, streamId: any, value: any, index: any, chunks: any) {
    updateValue(id, streamId, value, index, chunks);
  }
  static init(element: any, blazorConf: any, dotNetRef: any) {
    init(element, blazorConf, dotNetRef);
  }
  static destroy(id: string | number){
    destroy(id);
  }
}

function insertContent(id: string | number, content: string, args: any) {
  const tiny = tinymce.get(id);
  tiny?.insertContent(content, args);
}
function updateMode(id: string | number, disable: any) {
  const tiny = tinymce.get(id);
  if (tiny.mode && typeof tiny.mode.set === 'function') {
    tiny.mode.set(disable ? 'readonly' : 'design');
  }
}
function updateValue(id: string | number, streamId: any, value: any, index: any, chunks: any) {
  chunkMap.push(streamId, id, value, index, chunks);
}

const updateTinyVal = (id: string | number, val: string) => {
  var editor = tinymce.get(id);
  if (editor.getContent() !== val) {
    editor.setContent(val);
  }
};

const chunkMap = (() => {
  const map = new Map();
  const next = (streamId: any, editorId: string | number, val: any, index: any, size: any) => {
    const acc = (map.has(streamId) ? map.get(streamId) : "") + val;
    if (index === size) {
      updateTinyVal(editorId, acc);
      map.delete(streamId);
    } else {
      map.set(streamId, acc);
    }
  };
  return {
    push: next
  };
})();

function init(element: any, blazorConf: any, dotNetRef: any) {
  const chunkSize = 16 * 1024;
  const update = (format: string, content: string) => {
    const updateFn = format === 'text' ? 'UpdateText' : 'UpdateModel';
    const chunks = Math.floor(content.length / chunkSize) + 1;
    const streamId = (Date.now() % 100000) + '';
    for (let i = 0; i < chunks; i++) {
      const chunk = content.substring(chunkSize * i, chunkSize * (i + 1));
      dotNetRef.invokeMethodAsync(updateFn, streamId, i + 1, chunk, chunks);
    }
  };
  const getJsObj = (objectPath: any) => {
    const jsConf = (objectPath !== null && typeof objectPath === 'string') ?
      objectPath.split('.').reduce((acc, current) => {
        acc !== undefined ? acc[current] : undefined;
        return acc;
      }, window) : undefined;
    return (jsConf !== undefined && typeof jsConf === 'object') ? jsConf : {};
  };
  const tinyConf = { ...getJsObj(blazorConf.jsConf), ...blazorConf.conf };
  tinyConf.inline = blazorConf.inline;
  tinyConf.readonly = blazorConf.disabled;
  tinyConf.target = element;
  tinyConf._setup = tinyConf.setup;
  tinyConf.setup = (editor: any) => {
    if (tinyConf.singleLine == true || tinyConf.singleLine == "true"){
      editor.on('keydown', function(e: KeyboardEvent) {
        return (e.keyCode != 13);
      });
    }

    /* example, adding a toolbar menu button */
    editor.ui.registry.addMenuButton('smartfields', {
      text: 'Insertar campos',
      fetch: (callback: any) => {
        const items = [
          {
            type: 'menuitem',
            text: 'Tipo de Ambiente',
            onAction: () => editor.insertContent('{{TipoAmbiente}}')
          },
          {
            type: 'menuitem',
            text: 'Dirección del establecimiento',
            onAction: () => editor.insertContent('{{EstablecimientoDireccion}}')
          },
          {
            type: 'nestedmenuitem',
            text: 'Documento',
            getSubmenuItems: () => [
              {
                type: 'menuitem',
                text: 'Tipo de documento',
                onAction: () => editor.insertContent('{{TipoDocumento}}')
              },
              {
                type: 'menuitem',
                text: 'Número de documento',
                onAction: () => editor.insertContent('{{DocumentoNumero}}')
              },
              {
                type: 'menuitem',
                text: 'Clave de Acceso',
                onAction: () => editor.insertContent('{{ClaveAcceso}}')
              },
              {
                type: 'menuitem',
                text: 'Número de autorización',
                onAction: () => editor.insertContent('{{AutorizacionNumero}}')
              },
              {
                type: 'menuitem',
                text: 'Fecha de autorización',
                onAction: () => editor.insertContent('{{AutorizacionFecha}}')
              },
              {
                type: 'menuitem',
                text: 'Fecha de emisión',
                onAction: () => editor.insertContent('{{DocumentoFechaEmision}}')
              }
            ]
          },
          {
            type: 'nestedmenuitem',
            text: 'Emisor',
            getSubmenuItems: () => [
              {
                type: 'menuitem',
                text: 'RUC',
                onAction: () => editor.insertContent('{{EmisorRUC}}')
              },
              {
                type: 'menuitem',
                text: 'Razón social',
                onAction: () => editor.insertContent('{{EmisorRazonSocial}}')
              },
              {
                type: 'menuitem',
                text: 'Nombre comercial',
                onAction: () => editor.insertContent('{{EmisorNombreComercial}}')
              },
              {
                type: 'menuitem',
                text: 'Dirección de matriz',
                onAction: () => editor.insertContent('{{EmisorDireccionMatriz}}')
              },
              {
                type: 'menuitem',
                text: 'Dirección del establecimiento',
                onAction: () => editor.insertContent('{{EstablecimientoDireccion}}')
              }
            ]
          },
          {
            type: 'nestedmenuitem',
            text: 'Sujeto',
            getSubmenuItems: () => [
              {
                type: 'menuitem',
                text: 'Tipo de identificación',
                onAction: () => editor.insertContent('{{SujetoTipoIdentificacion}}')
              },
              {
                type: 'menuitem',
                text: 'Identificación',
                onAction: () => editor.insertContent('{{SujetoIdentificacion}}')
              },
              {
                type: 'menuitem',
                text: 'Razón social',
                onAction: () => editor.insertContent('{{SujetoRazonSocial}}')
              }
            ]
          }
        ];
        callback(items);
      }
    });

    tinyEventHandler.bindEvent(editor, 'init', (e: any) => dotNetRef.invokeMethodAsync('GetValue').then((value: any) => { editor.setContent(value); }));
    tinyEventHandler.bindEvent(editor, 'change', (e: any) => { dotNetRef.invokeMethodAsync('OnChange'); });
    tinyEventHandler.bindEvent(editor, 'input', (e: any) => { dotNetRef.invokeMethodAsync('OnInput'); });
    tinyEventHandler.bindEvent(editor, 'setcontent', (e: any) => update('text', editor.getContent({ format: 'text' })));
    tinyEventHandler.bindEvent(editor, blazorConf.modelEvents, (e: any) => {
      update('html', editor.getContent());
      update('text', editor.getContent({ format: 'text' }));
    });
    if (tinyConf._setup && typeof tinyConf._setup === 'function') {
      tinyConf._setup(editor, tinyEventHandler);
    }
  }

  tinymce.init(tinyConf);
}

function destroy(id: string | number) {
  var editor = tinymce.get(id);
  if (editor) {
    tinyEventHandler.unbindEditor(id);
    editor.remove();
  }
}

const tinyEventHandler = (() => {
  const eventCache: {[key: string]: any[]} = {};
  const bindEvent = (editor: Editor, event: any, fn: any) => {
    if (!eventCache[editor.id]) eventCache[editor.id] = [];
    eventCache[editor.id].push({ name: event, fn });
    editor.on(event, fn);
  };
  const unbindEditor = (editorId: string | number) => {
    const editor = tinymce.get(editorId);
    eventCache[editorId].forEach((event: { name: any; fn: (event: any) => void; }, i: any) => {
      editor.off(event.name, event.fn);
    });
    delete eventCache.editorId;
  };
  return {
    bindEvent,
    unbindEditor
  }
})();


